import React from 'react';
import { OurStoryItem } from 'ui-components';

const OurStoryPart1 = () => {
  return (
    <>
      <OurStoryItem title="Introduction">
        Welcome to Modern Surfaces, LLC, a Veteran Owned Business, and the premier hardwood specialists serving the Pacific Northwest and Puget Sound regions. With our unwavering commitment to excellence and expertise in all things flooring, we are here to transform your space into a breathtaking masterpiece.
      </OurStoryItem>
      <OurStoryItem title="Daniela's Journey">
        Daniela's passion for fitness and her military background in the Air Force National Guard have shaped her into an exceptional individual with a unique skillset. Her dedication to fitness and discipline gained through military service have instilled in her the qualities of organization, attention to detail, and unwavering commitment to excellence.
      </OurStoryItem>
    </>
  );
}

export default OurStoryPart1;
