import {
  CallToAction1,
  CallToAction2,
  CallToAction3,
  DefaultBackground,
  FreeQuoteBackground1,
  FreeQuoteBackground2,
  HomepageBackground,
  WorkImage1,
  WorkImage10,
  WorkImage11,
  WorkImage12,
  WorkImage13,
  WorkImage14,
  WorkImage2,
  WorkImage3,
  WorkImage4,
  WorkImage5,
  WorkImage6,
  WorkImage7,
  WorkImage8,
  WorkImage9
} from "assets";

export const ROUTES = {
  HOME: "/",
  ABOUT_US: "/about",
  OUR_SERVICES: "/services",
  GALLERY: "/gallery",
  CONTACT: "/contact",
  PRIVACY_POLICY: "/privacy",
  TERMS_OF_SERVICE: "/terms",
  COOKIE_POLICY: "/cookie",
}

export const PAGE_TITLES = {
  [ROUTES.HOME]: "Home",
  [ROUTES.ABOUT_US]: "About Us",
  [ROUTES.OUR_SERVICES]: "Our Services",
  [ROUTES.GALLERY]: "Gallery",
  [ROUTES.CONTACT]: "Contact Us",
  [ROUTES.PRIVACY_POLICY]: "Privacy Policy",
  [ROUTES.TERMS_OF_SERVICE]: "Terms of Service",
  [ROUTES.COOKIE_POLICY]: "Cookie Policy",
}

export const NAVIGATION_LABELS = {
  HOME: "Home",
  ABOUT_US: "About",
  OUR_SERVICES: "Services",
  GALLERY: "Gallery",
  CONTACT: "Contact",
  PRIVACY_POLICY: "Privacy Policy",
  TERMS_OF_SERVICE: "Terms of Service",
  COOKIE_POLICY: "Cookie Policy",
}

export const NAVBAR_IMAGES = {
  HOME: HomepageBackground,
  DEFAULT: DefaultBackground,
}

export const FREE_QUOTE_IMAGES = {
  HOME: FreeQuoteBackground1,
  CONTACT: FreeQuoteBackground2,
};

export const CALL_TO_ACTION_IMAGES = {
  ABOUT_US: CallToAction1,
  OUR_SERVICES: CallToAction2,
  GALLERY: CallToAction3,
};

export const PARAGRAPH_SECTION_TYPES = {
  LIST: "list",
  PARAGRAPHS: "paragraphs",
  TABLE: "table"
}

export const SOCMED_LINKS = {
  FACEBOOK: "https://www.facebook.com/profile.php?id=100092389997955&mibextid=2JQ9oc",
  INSTAGRAM: "https://instagram.com/modernsurfacesllc?igshid=MzRlODBiNWFlZA==",
  LINKEDIN: "",
}

export const WORK_IMAGES = [
  {
    src: WorkImage1,
    alt: "This image shows a room featuring brown hardwood floors and a stone fireplace."
  },
  {
    src: WorkImage2,
    alt: "This image shows a kitchen featuring white cabinets and hardwood floors."
  },
  {
    src: WorkImage3,
    alt: "This image shows a room featuring polished brown hardwood floors and a stone fireplace."
  },
  {
    src: WorkImage4,
    alt: "This image shows a kitchen with brown hardwood floors and stainless steel appliances, including an oven and refrigerator."
  },
  {
    src: WorkImage5,
    alt: "This image shows a room with polished brown hardwood floors and a fireplace with logs in front."
  },
  {
    src: WorkImage6,
    alt: "This image shows a room with polished dark hardwood floors and a tv hanging on the wall."
  },
  {
    src: WorkImage7,
    alt: "This image shows a room with polished orange hardwood floors and large windows throughout the whole wall."
  },
  {
    src: WorkImage8,
    alt: "This image shows a room with white hardwood floors, doric columns, and a marble fireplace."
  },
  {
    src: WorkImage9,
    alt: "This image shows a flight of polished light brown staircase."
  },
  {
    src: WorkImage10,
    alt: "This image shows a semi-furnished room with brown hardwood floors and unopened boxes."
  },
  {
    src: WorkImage11,
    alt: "This image shows a top view of wooden staircases."
  },
  {
    src: WorkImage12,
    alt: "This image shows a hallway with polished brown hardwood floors."
  },
  {
    src: WorkImage13,
    alt: "This image shows a kitchen with polished brown floors, dark brown island, and green cabinets."
  },
  {
    src: WorkImage14,
    alt: "This image shows a room with polished brown hardwood floors and a radiator."
  },
];