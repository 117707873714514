import { Flex, Text, View } from "@aws-amplify/ui-react";
import React from "react";
import { StarIcon } from "ui-components";

const Testemonial = (props) => {
  const { children, person } = props;
  return (
    <Flex
      as="li"
      border="1px solid rgba(222, 222, 222, 1)"
      direction="column"
      gap="1.25rem"
      padding="2rem 2.25rem"
    >
      <Flex gap="0">
        {[...Array(5)].map((_, index) => (
          <StarIcon key={index} />
        ))}
      </Flex>
      <Text
        as="blockquote"
        textAlign="left"
        fontWeight={600}
        fontSize="0.875rem"
        lineHeight="1.5rem"
        color="rgba(115, 115, 115, 1)"
      >
        {children}
      </Text>
      <View>
        <Text
          textAlign="left"
          fontWeight={600}
          fontSize="0.875rem"
          lineHeight="1.5rem"
          color="rgba(255, 123, 71, 1)"
        >
          {person.name}
        </Text>
        <Text
          textAlign="left"
          fontWeight={600}
          fontSize="0.875rem"
          lineHeight="1.5rem"
          color="rgba(37, 43, 66, 1)"
        >
          {person.role}
        </Text>
      </View>
    </Flex>
  );
}

export default Testemonial;