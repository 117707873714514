import { Flex, Text, View } from "@aws-amplify/ui-react";
import React from "react";
import { ParagraphSection } from "ui-components";
import { PARAGRAPH_SECTION_TYPES } from "utils/constants";

const TermsAndConditions = (props) => {
  return (
    <View padding="1.25rem">
      <Text
        as="h2"
        fontWeight={700}
        fontSize="2.25rem"
        lineHeight="3.5rem"
        marginBottom="1.75rem"
      >
        Terms & Conditions of Modern Surfaces
      </Text>
      <Flex
        direction="column"
        gap="1.25rem"
        maxWidth="1051px"
        textAlign="left"
      >
        <ParagraphSection>
          This page states the Terms and Conditions under which you, the Web Site visitor (“You”) may use www.modernsurfaces.pro (the “Site”). Modern Surfaces, LLC owns the Site. By using this Site, You agree to be bound by all of the Terms and Conditionsz set forth below. Please do not use this Site if You do not accept these Terms and Conditions.
          <br />
          These Terms and Conditions supersede any other agreement You may have with us regarding this subject matter. We may, in our sole discretion, revise these Terms and Conditions at any time; therefore, You should visit this page periodically to review the Terms and Conditions.
        </ParagraphSection>
        <ParagraphSection
          title="Site Material Use"
          type={PARAGRAPH_SECTION_TYPES.PARAGRAPHS}
          content={[
            "The contents of this Site, including text, graphics, images and other content (the “Site Material”) are protected by copyright under both United States and foreign laws. These Terms and Conditions do not grant You any license whatsoever to the Site Material. We authorize You to view and download a single copy of the Site Material for non-commercial personal use.",
            "Unauthorized use of the Site Material violates copyright, trademark, and other laws. Use of the Site Material on any other web site or in a networked environment is prohibited. We retain all intellectual property rights in the Site Material. You agree to retain all copyright and proprietary notices contained in the original Site Material on any copy of such material. Except as expressly provided herein, You may not sell or modify our Site Material or reproduce, display, distribute, or otherwise use the Site Material in any way for any public or commercial purpose."
          ]}
        />
        <ParagraphSection title="Trademarks">
          Your use of Our Trademarks, except as provided in these Terms and Conditions, is prohibited. We make fair use in this Site of trademarks owned and used by third parties from time to time. We make no claim to ownership of those marks.
        </ParagraphSection>
        <ParagraphSection title="Submissions by Users">
          If you submit comments on the site, You acknowledge that if You send us creative suggestions, ideas, recipes, notes, drawings, concepts, inventions or other information (collectively, the “Information”), the Information shall be deemed, and shall remain, our property. By submission of any Information to us, You assign to us and we shall own exclusively all rights to such Information of every kind and nature throughout the universe now known or hereafter existing. We shall be entitled to unrestricted use of the Information for any purpose whatsoever, commercial or otherwise, without compensation or attribution to You or the provider of the information.
        </ParagraphSection>
        <ParagraphSection
          type={PARAGRAPH_SECTION_TYPES.LIST}
          list={[
            "send to us material that is copyrighted, unless You are the copyright owner or have the permission of the copyright owner to transmit the materials to us;",
            "send material that is obscene, defamatory, threatening, harassing, abusive, hateful, or embarrassing to another user or any other person or entity;",
            "intentionally or unintentionally violate or encourage conduct that would violate any local, state, or federal law;",
            "send material that reveals trade secrets, unless You own them or have the express permission of the owner to disclose those materials to us",
            "send material that infringes on any other intellectual property rights of others or on the privacy or publicity rights of others;",
            "send advertisements or solicitations of business;",
            "impersonate another person."
          ]}
        >
          As a user of this Site, You are responsible for Your own communications. Do not do any of the following:"
        </ParagraphSection>
        <ParagraphSection>
          We reserve the right to prevent You access to this Site for violating these Terms and Conditions or any applicable law. The violation of any of these Terms and Conditions shall result in the immediate revocation of your right to access or use the Site or Site Material and obligates You to immediately destroy any copies of the Site Material in your possession.
        </ParagraphSection>
        <ParagraphSection
          title="Limitation of Liability"
          type={PARAGRAPH_SECTION_TYPES.PARAGRAPHS}
          content={[
            "NO WARRANTY IS HEREBY MADE THAT THE SITE WILL OPERATE ERROR-FREE OR THAT THE SITE AND ITS SERVER ARE FREE OF COMPUTER VIRUSES OR OTHER HARMFUL MATERIAL. IF YOUR USE OF THE SITE OR THE SITE MATERIAL RESULTS IN ANY COSTS OR EXPENSES, INCLUDING, WITHOUT LIMITATION, THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, YOU SHALL BE SOLELY FOR ANY SUCH COSTS OR EXPENSES.",
            "THIS SITE AND ITS MATERIAL ARE PROVIDED ON AN “AS IS” AND “AT YOUR OWN RISK” BASIS WITHOUT ANY WARRANTIES OF ANY KIND. ALL WARRANTIES ARE HEREBY DISCLAIMED TO THE FULLEST EXTENT PERMITTED BY LAW, INCLUDING THE WARRANTY OF MERCHANTABILITY, NON- INFRINGEMENT OF THIRD PARTIES RIGHTS, AND THE WARRANTY OF FITNESS FOR PARTICULAR PURPOSE. ALTHOUGH WE STRIVE TO PROVIDE THOROUGH AND ACCURATE MATERIALS ON ITS SITE, NO WARRANTIES ARE HEREBY MADE ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE MATERIAL, SERVICES, SOFTWARE, TEXT, GRAPHICS, AND LINKS."
          ]}
        />
        <ParagraphSection title="Disclaimer of Consequential Damages">
          IN NO EVENT SHALL THE OWNER OR OPERATOR OF THIS WEBSITE, AD PRACTITIONER PROJECTS, LLC, THEIR RESPECTIVE AFFILIATES, OR ANY THIRD PARTIES MENTIONED ON THE SITE BE LIABLE FOR ANY DAMAGES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, INCIDENTAL, INDIRECT, CONSEQUENTIAL OR PUNITIVE DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST DATA OR BUSINESS INTERRUPTION) RESULTING FROM THE USE OR INABILITY TO USE MATERIAL ON THIS SITE OR SITES LINKED TO THIS SITE, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANY PARTY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </ParagraphSection>
        <ParagraphSection title="Indemnity">
          By using this Site, You agree to defend, indemnify, and hold us (including our affiliates, officers, directors, employees and agents), harmless from and against any and all losses, claims, damages, costs and expenses (including reasonable legal and accounting fees) that we may become obligated to pay arising or resulting from your use of the Site Material or your breach of these Terms and Conditions. We reserve the right to assume or participate, at your expense, in the investigation, settlement and defense of any such action or claim.
        </ParagraphSection>
        <ParagraphSection title="Links">
          This Site may contain links to websites owned by third parties. These links are provided only as a convenience to You and are not an endorsement of the contents on those other sites. We are not responsible for the content of any linked sites and make no representations regarding the content or accuracy of materials on such sites. Consult the web URL address identified in your browser to determine whether you have linked to a third-party Site. If You decide to visit any third-party sites using links from this Site, You do so at your own risk.
        </ParagraphSection>
        <ParagraphSection
          title="Miscellaneous"
          type={PARAGRAPH_SECTION_TYPES.PARAGRAPHS}
          content={[
            "This Site originates from the United States, and we do not claim that the Site Material is appropriate or may be used outside of the United States. Access to the Site Material may not be legal by certain persons or in certain countries. If You access the Site from outside of the United States, You do so at your own risk and are responsible for compliance with the laws of your jurisdiction. These Terms and Conditions are governed by the laws of the State of Delaware, without respect to its conflict of laws principles. You agree to submit to the jurisdiction of the courts of Delaware with respect to any dispute, disagreement, or cause of action related to or involving this Site.",
            "If any provision is found to be invalid by any court having competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms and Conditions, which shall remain in full force and effect.",
            "No waiver of any of these Terms and Conditions shall be deemed a further or continuing waiver of such term or any other term.Except as expressly provided elsewhere in our Site, these Terms and Conditions constitute our entire agreement with respect to your use of this Site.",
            "If you have any questions about these Terms and Conditions please contact us."
          ]}
        />
      </Flex>
    </View>
  );
}

export default TermsAndConditions;
