import { Flex, Image, Text, View } from "@aws-amplify/ui-react";
import React from "react";
import { StarIcon } from "ui-components";

const Process = (props) => {
  const {
    checkpoint,
    children,
    image,
    imageAlt,
    isReversed,
    title
  } = props;
  return (
    <Flex
      as="li"
      maxWidth={{
        base: "100%",
        large: "786px"
      }}
      border="1px solid rgba(232, 232, 232, 1)"
      gap={{
        base: "0.75rem",
        large: "2.75rem"
      }}
      direction={{
        base: "column",
        large: isReversed ? "row-reverse" : "row"
      }}
      alignSelf={isReversed ? "flex-end" : "flex-start"}
    >
      <Image src={image} alt={imageAlt} />
      <Flex
        paddingTop={{
          base: "1.25rem",
          large: "1rem"
        }}
        paddingBottom={{
          base: "1.25rem",
          large: "1rem"
        }}
        paddingRight={{
          base: "1.25rem",
          large: isReversed ? "0" : "2rem"
        }}
        paddingLeft={{
          base: "1.25rem",
          large: isReversed ? "2rem" : "0"
        }}
        alignItems="flex-start"
        justifyContent="center"
        direction="column"
        textAlign="left"
      >
        <Text
          as="h3"
          fontWeight={700}
          fontSize="1rem"
          lineHeight="1.5rem"
          marginBottom="0.25rem"
        >
          {title}
        </Text>
        <Text
          fontSize="0.875rem"
          lineHeight="1.25rem"
          color="rgba(115, 115, 115, 1)"
        >
          {children}
        </Text>
        {checkpoint && (
          <Flex>
            <StarIcon isHollow />
            <View>
              <Text
                as="strong"
                fontWeight={800}
                fontSize="0.875rem"
                lineHeight="1.25rem"
                color="rgba(115, 115, 115, 1)"
              >
                Customer Checkpoint: &nbsp;
              </Text>
              <Text
                as="span"
                fontSize="0.875rem"
                lineHeight="1.25rem"
                color="rgba(115, 115, 115, 1)"
                fontWeight={500}
              >
                {checkpoint}
              </Text>
            </View>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}

export default Process;