import { Flex, Text, View } from "@aws-amplify/ui-react";
import React from "react";
import { ParagraphSection } from "ui-components";
import { PARAGRAPH_SECTION_TYPES } from "utils/constants";

const CookiePolicy = () => {
  return (
    <View padding="20px">
      <Text
        as="h2"
        fontWeight={700}
        fontSize="2.25rem"
        lineHeight="3.5rem"
        marginBottom="1.75rem"
      >
        Cookie Policy of Modern Surfaces
      </Text>
      <Flex
        direction="column"
        gap="1.25rem"
        maxWidth="1051px"
        textAlign="left"
      >
        <ParagraphSection>
          This cookie policy (“Cookie Policy”) describes the types of cookies, pixels, local storage objects, and similar devices (collectively, “cookies” unless otherwise noted) we use on the Modern Surfaces website and our purposes for using them.If you have questions about this Cookie Policy, please do not hesitate to contact us.For more information about our privacy practices, please review our privacy policy.
        </ParagraphSection>
        <ParagraphSection title="What is a cookie?">
          A cookie is a small file that websites send to your computer or other Internet-connected devices to uniquely identify your browser or to store information or settings in your browser. Unless you have adjusted your browser setting so that it will refuse cookies, our website will issue cookies as soon as you visit the website.
        </ParagraphSection>
        <ParagraphSection
          title=""
          type={PARAGRAPH_SECTION_TYPES.PARAGRAPHS}
          content={[
            "You can control and/or delete cookies as you wish – for details, see aboutcookies.org. You can delete all cookies that are already on your computer and you can set most browsers to prevent them from being placed. If you do this, however, you may have to manually adjust some preferences every time you visit a site and some services and functionalities may not work.",
            "These cookie settings are usually found in the 'options' or 'preferences' menu of your internet browser. In order to understand these settings, the following links may be helpful. Otherwise you should use the 'Help' option in your internet browser for more details.",
            (
              <>Cookie settings in Internet Explorer<br />
                Cookie settings in FirefoxCookie settings in Chrome<br />
                Cookie settings in Safari
              </>
            ),
            "To opt - out of third - parties collecting any data regarding your interaction on our website, please refer to their websites for further information."
          ]}
        />
        <ParagraphSection title="More information on cookies">
          To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit aboutcookies.org or allaboutcookies.org.
        </ParagraphSection>
        <ParagraphSection title="What cookies do we use?">
          Quantify 36 may use the following cookies:
          <br />
          1. First and third-party cookies: whether a cookie is 'first' or 'third' party refers to the domain placing the cookie. First-party cookies are those set by a website that is being visited by the user at the time.2. Third-party cookies are cookies that are set by a domain other than that of the website being visited by the user. If a user visits a website and another entity sets a cookie through that website, this would be a third-party cookie.
        </ParagraphSection>
        <ParagraphSection
          title="Duration of Cookies"
          type={PARAGRAPH_SECTION_TYPES.PARAGRAPHS}
          content={[
            "1. Session cookies: these cookies allow website operators to link the actions of a user during a browser session. A browser session starts when a user opens the browser window and finishes when they close the browser window. Session cookies are created temporarily. Once you close the browser, all session cookies are deleted.",
            "2. Persistent cookies: these cookies remain on a user's device for the period of time specified in the cookie. They are activated each time that the user visits the website that created that particular cookie.",
            "We only use the types of cookies described above. We don't use so-called ‘specialist cookies'. In addition, Modern Surfaces uses Google Analytics for the only purpose of obtaining the report of the activity that is provided."
          ]}
        />
      </Flex>
    </View>
  );
}

export default CookiePolicy;
