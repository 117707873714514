/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Grid, View } from "@aws-amplify/ui-react";
import Testemonial from "./Testemonial";
import { SectionHeader } from "ui-components";
export default function WhatOurClientsSaySection() {
  return (
    <View
      as="section"
      width="100%"
      display="block"
      gap="unset"
      alignItems="unset"
      justifyContent="unset"
      overflow="hidden"
      position="relative"
      padding={{
        base: "2rem 1.5rem",
        medium: "2.5rem 1.5rem",
        xl: "2.5rem 0"
      }}
      backgroundColor="rgba(255,255,255,1)"
    >
      <SectionHeader title="What Our Clients Say">
        Hear from our satisfied clients who rave about our unmatched craftsmanship and stunning results.
      </SectionHeader>
      <Grid
        as="ul"
        gap="2rem"
        maxWidth={1048}
        templateColumns={{
          base: "1fr",
          medium: "1fr 1fr",
          xl: "1fr 1fr 1fr"
        }}
        alignItems={{
          base: "unset",
          xl: "flex-start"
        }}
        autoFlow="dense"
      >
        <Testemonial
          person={{
            name: "Charleen",
            role: "School District Counselor and Interventionist"
          }}
        >
          Pete refinished by hardwood floors and installed a set of stairs. He was hard-working, conscientious, and paid attention to detail.
          <br /><br />
          I was happy with the work and plan to use him again to install a hardwood floor in my family room.
        </Testemonial>
        <Testemonial
          person={{
            name: "Steven Shelton",
            role: "Real Estate Broker"
          }}
        >
          Modern Surfaces is pure floor magic! Peter, the magician of floors, impressed me with his knowledge, flexibility, and creativity. He transformed my oak floors in Normandy Park, WA into a shiny masterpiece that looks brand new.
          <br /><br />
          Their promptness and craftsmanship exceeded my expectations. If you want the Picasso of floor refinishing, call Modern Surfaces. They're the Gandalf of floors, ready to create a wood wonderland in your home.
        </Testemonial>
        <Testemonial
          person={{
            name: "Rick and Karen",
            role: "Puget Sound Homeowners"
          }}
        >
          Piotr Szmit with Modern Surfaces, LLC, is an amazing hardwood floor craftsman.
          <br /><br />
          His work ethic is outstanding and the company's prices are very competitive.
        </Testemonial>
      </Grid>
    </View>
  );
}
