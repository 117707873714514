import React from 'react';
import { SeeMoreOfOurWork } from './sections';
import { CallToAction } from 'ui-components';
import { CALL_TO_ACTION_IMAGES } from 'utils/constants';

const Gallery = () => {
  return (
    <>
      <SeeMoreOfOurWork />
      <CallToAction image={CALL_TO_ACTION_IMAGES.GALLERY} />
    </>
  );
}

export default Gallery;
