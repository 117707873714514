/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import { GetFreeEstimateButton, SectionHeader } from "ui-components";
import Owner from "./Owner";
import { DanielaSzmit, PiotrSzmit } from "assets";
export default function MeetTheOwners() {
  return (
    <View
      as="section"
      padding="1.5rem 0 4rem"
    >
      <SectionHeader title="Meet The Owners">
        Get to know the passionate and experienced owners behind
        <br />
        Modern Surfaces, LLC, dedicated to delivering excellence.
      </SectionHeader>
      <Flex
        direction="column"
        gap={{
          base: "3rem",
          medium: "5rem",
          xl: "7.75rem"
        }}
        maxWidth={888}
        padding={{
          base: "0 1.25rem",
          large: "0"
        }}
      >
        <Owner
          image={PiotrSzmit}
          name="Piotr Szmit"
          role="Master Craftsman and Owner"
          imageAlt="A photo showing one of the owners, Piotr Szmit"
        >
          Piotr, a native of Swinoujscie, Poland, is a highly skilled flooring contractor with a lifelong passion for hardwood flooring. With over
          two decades of experience, Piotr has honed his expertise in installation, refinishing, and repair.From his early days working with renowned flooring companies like Seattle Floor Service and Elliott Bay Hardwood Floors, Piotr's meticulous attention to detail and commitment to excellence have set him apart as a master craftsman.
          <br /><br />
          As the owner of Modern Surfaces, Piotr leads a team dedicated to
          delivering unparalleled craftsmanship and personalized service. With an unwavering focus on precision and artistry, Piotr ensures that every flooring project, from luxurious residences to prestigious commercial spaces, reaches new levels of excellence.
        </Owner>
        <Owner
          image={DanielaSzmit}
          name="Daniela Szmit"
          role="Office Manager and Owner"
          imageAlt="A photo showing one of the owners, Daniela Szmit"
        >
          Daniela is a highly accomplished Office Manager and Customer Service Professional at Modern Surfaces, LLC, leveraging her military training from the Air Force National Guard and extensive experience as a fitness instructor.
          <br /><br />
          With exceptional organizational skills, attention to detail, and a commitment to excellence, Daniela ensures smooth operations and outstanding customer experiences. Her customer service expertise, honed through building strong client relationships as a fitness instructor, translates seamlessly into the flooring contractor industry.
          <br /><br />
          With a disciplined work ethic and effective communication, Daniela excels in coordinating schedules, managing projects, and delivering top-notch service. Her unique skill set and dedication position Modern Surfaces as a leader in providing exceptional flooring solutions.
        </Owner>
        <Flex
          direction="column"
          gap="0"
          alignItems="center"
        >
          <Text
            fontWeight={400}
            fontSize="1.5rem"
            lineHeight="2rem"
          >
            Together We Make
          </Text>
          <Text
            as="strong"
            fontWeight={700}
            fontSize={{
              base: "1.5rem",
              large: "2.25rem"
            }}
            lineHeight={{
              base: "2.25rem",
              large: "3rem"
            }}
            marginBottom="1.5rem"
          >
            Modern Surfaces, LLC: Your Trusted
            <br />
            Flooring Experts in the Pacific Northwest
          </Text>
          <GetFreeEstimateButton />
        </Flex>
      </Flex>
    </View>
  );
}
