import { Table, TableBody, TableCell, TableHead, TableRow } from "@aws-amplify/ui-react";
import React from "react";

const PrivacyTable = (props) => {
  const { headers, data } = props;
  return (
    <Table>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell as="th" key={index}>{header.title}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            {headers.map((column, cellIndex) => (
              <TableCell key={cellIndex}>{row[column.key]}</TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default PrivacyTable;
