/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Flex, View } from "@aws-amplify/ui-react";
import { SectionHeader } from "ui-components";
import Process from "./Process";
import {
  OurProcess1,
  OurProcess2,
  OurProcess3,
  OurProcess4,
  OurProcess5,
  OurProcess6,
  OurProcess7
} from "assets";

const PROCESSES = [
  {
    image: OurProcess1,
    imageAlt: "This photo shows a man wearing a white shirt and hard hat talking to another man wearing a black shirt who's looking at two sheets of paper.",
    title: <>
      Initial Contact and
      <br />
      Consultation
    </>,
    content: "Customers begin by contacting Modern Surfaces, LLC for an in-depth consultation to discuss their needs, vision, and specific project requirements.",
    checkpoint: "Approval of project specifications and discussion points."
  },
  {
    image: OurProcess2,
    imageAlt: "This photo has two people, with only their arms showing, leaning on a desk and looking over paparwork.",
    title: "Project Quote",
    content: "After the consultation, we'll provide a detailed, transparent quote. This includes a breakdown of costs for labor, materials, and any additional services needed.",
    checkpoint: "Review and approval of the quote before moving forward."
  },
  {
    image: OurProcess3,
    imageAlt: "This photo shows two men looking at each other while the one on the left is holding a rolled out blueprint.",
    title: "Project Planning",
    content: "After the consultation, we'll provide a detailed, transparent quote. This includes a breakdown of costs for labor, materials, and any additional services needed.",
    checkpoint: "Final approval of the project plan and timeline."
  },
  {
    image: OurProcess4,
    imageAlt: "This photo shows a pair of hands wearing white gloves and measuring a panel of wood with a steel measuring tape.",
    title: "Execution",
    content: "Our team of hardwood specialists will begin the installation, repair, or refinishing work. We ensure meticulous attention to detail and minimal disruption.",
    checkpoint: "Regular updates and approvals at key stages of work progress."
  },
  {
    image: OurProcess5,
    imageAlt: "This photo shows a man in construction attire kneeling down on the ground to place down a white wooden floor panel.",
    title: <>
      Final Review and
      <br />
      Finishing
    </>,
    content: "After the main work, if the project requires it, we'll apply the chosen high-quality finishes. The customer is then invited for a final review.",
    checkpoint: "Approval of the finished work and any necessary adjustments."
  },
  {
    image: OurProcess6,
    imageAlt: "This photo shows a man in a plaid shirt and yellow hard hat looking directly at the camera doing a thumbs up pose.",
    title: "Project Completion",
    content: "With customer approval, we'll clean the area, leaving the space looking its best. We'll also provide the customer with advice on maintaining their new or refurbished floors.",
    checkpoint: "Sign-off on the completed project."
  },
  {
    image: OurProcess7,
    imageAlt: "This photo shows a women smiling while wearing a headset.",
    title: "Follow-up",
    content: "We believe in nurturing long-term relationships, and we'll check in with the customer after project completion to ensure ongoing satisfaction and answer any further questions.",
  },
]
export default function OurProcess() {
  return (
    <View
      as="section"
      padding="1.5rem 0 2.5rem"
    >
      <SectionHeader title="Our Process">
        Experience a seamless and stress-free flooring project
        <br />
        with our efficient and meticulous step-by-step process.
      </SectionHeader>
      <Flex
        as="ul"
        maxWidth="1056px"
        direction="column"
        gap="2.25rem"
        padding={{
          base: "0 1.25rem",
          xl: "0"
        }}
      >
        {PROCESSES.map((process, index) => (
          <Process
            key={index}
            image={process.image}
            imageAlt={process.imageAlt}
            title={process.title}
            checkpoint={process.checkpoint}
            isReversed={index % 2 === 1}
          >
            {process.content}
          </Process>
        ))}
      </Flex>
    </View>
  );
}
