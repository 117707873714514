import { Flex, Text, View } from '@aws-amplify/ui-react';
import React from 'react';
import { SectionTable } from 'ui-components';
import { PARAGRAPH_SECTION_TYPES } from 'utils/constants';

const ParagraphSection = (props) => {
  const { children, content, inline, list, table, title, type } = props;
  const displayContent = (sectionType) => {
    switch (sectionType) {
      case PARAGRAPH_SECTION_TYPES.PARAGRAPHS:
        return (
          <>
            {content.map((paragraph, index) => (
              <Text
                key={index}
                fontSize="0.875rem"
                lineHeight="1.25rem"
                color="rgba(115, 115, 115, 1)"
              >
                {paragraph}
              </Text>
            ))}
          </>
        );
      case PARAGRAPH_SECTION_TYPES.LIST:
        return getListItem(list, sectionType);
      case PARAGRAPH_SECTION_TYPES.TABLE:
        return (
          <SectionTable
            headers={table.headers}
            data={table.data}
          />
        );
      default:
        break;
    }
  }

  const getListItem = (data) => {
    return (
      <ul style={{ margin: 0 }}>
        {data.map((item, index) => {
          if (typeof item === "string") {
            return (
              <li key={index}>
                <Text
                  fontSize="0.875rem"
                  lineHeight="1.25rem"
                  color="rgba(115, 115, 115, 1)"
                >
                  {item}
                </Text>
              </li>
            );
          } else {
            return (
              <li key={index}>
                <View>
                  {item.title && (
                    <Text
                      as="b"
                      fontWeight={800}
                      fontSize="0.875rem"
                      lineHeight="1.25rem"
                      color="rgba(115, 115, 115, 1)"
                    >
                      {item.title}&nbsp;
                    </Text>
                  )}
                  <Text
                    as="span"
                    fontSize="0.875rem"
                    lineHeight="1.25rem"
                    color="rgba(115, 115, 115, 1)"
                  >
                    {item.content}
                  </Text>
                  {item.type === PARAGRAPH_SECTION_TYPES.LIST && (
                    getListItem(item.list)
                  )}
                </View>
              </li>
            );
          }
        })}
      </ul>
    );
  }
  return (
    <Flex
      direction="column"
      gap={type === PARAGRAPH_SECTION_TYPES.PARAGRAPHS ? "1.25rem" : "0"}
    >
      {inline ? (
        <>
          {title && (
            <Text
              fontSize="0.875rem"
              lineHeight="1.25rem"
              color="rgba(115, 115, 115, 1)"
            >
              <Text
                as="span"
                fontWeight={800}
                fontSize="0.875rem"
                lineHeight="1.25rem"
                color="rgba(115, 115, 115, 1)"
              >
                {title}:&nbsp;
              </Text>
              <span>{children}</span>
            </Text>
          )}
        </>
      ) : (
        <>
          {title && (
            <Text
              fontWeight={800}
              fontSize="1rem"
              lineHeight="1.25rem"
              color="rgba(115, 115, 115, 1)"
              marginBottom={type === PARAGRAPH_SECTION_TYPES.PARAGRAPHS ? "0" : "1.25rem"}
            >
              {title}
            </Text>
          )}
          {children && (
            <Text
              fontSize="0.875rem"
              lineHeight="1.25rem"
              color="rgba(115, 115, 115, 1)"
            >
              {children}
            </Text>
          )}
        </>
      )}
      {(content || list || table) && displayContent(type)}
    </Flex >
  );
}

export default ParagraphSection;
