import React from 'react';
import { OurWork, WhatOurClientsSaySection, WhatWeDoSection } from './sections';
import { FREE_QUOTE_IMAGES } from 'utils/constants';
import { FreeQuote } from 'ui-components';

const HomePage = ({ deviceType }) => {
  return (
    <>
      <WhatWeDoSection />
      <OurWork deviceType={deviceType} />
      <WhatOurClientsSaySection />
      <FreeQuote
        image={FREE_QUOTE_IMAGES.HOME}
        imageAlt="This photo shows a room with a divider and ornate furniture and decorations."
      />
    </>
  );
}

export default HomePage;
