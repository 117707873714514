import { Icon, View } from '@aws-amplify/ui-react';
import React from 'react';

const FacebookIcon = ({ isColored, isLarge, isSquare }) => {
  return (
    <View
      width={isLarge ? "2.25rem" : "1.5rem"}
      height={isLarge ? "2.25rem" : "1.5rem"}
    >
      <Icon
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        paths={[
          {
            d: isSquare
              ? "M23.04 0H0.96C0.429 0 0 0.429 0 0.96V23.04C0 23.571 0.429 24 0.96 24H23.04C23.571 24 24 23.571 24 23.04V0.96C24 0.429 23.571 0 23.04 0ZM20.268 7.005H18.351C16.848 7.005 16.557 7.719 16.557 8.769V11.082H20.145L19.677 14.703H16.557V24H12.816V14.706H9.687V11.082H12.816V8.412C12.816 5.313 14.709 3.624 17.475 3.624C18.801 3.624 19.938 3.723 20.271 3.768V7.005H20.268Z"
              : "M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999 0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891 1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z",
            fill: isColored ? "rgba(51, 91, 245, 1)" : "rgba(255,255,255,1)",
            fillRule: "nonzero",
            width: "100%"
          },
        ]}
        display="block"
      />
    </View>
  );
}

export default FacebookIcon;
