/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
import { SectionHeader } from "ui-components";
import {
  OurStoryPart1,
  OurStoryPart2,
  OurStoryPart3,
  OurStoryPart4,
  OurStoryPart5,
} from ".";
import { OurStoryImage } from "assets";
export default function OurStory() {
  const SECTION_COUNT = 5;
  const SECTIONS = [
    <OurStoryPart1 />,
    <OurStoryPart2 />,
    <OurStoryPart3 />,
    <OurStoryPart4 />,
    <OurStoryPart5 />,
  ]
  const [sectionIndex, setSectionIndex] = React.useState(1);

  const nextIndex = () => {
    if (sectionIndex < SECTION_COUNT) {
      setSectionIndex(prev => prev + 1);
    }
  }

  const prevIndex = () => {
    if (sectionIndex > 1) {
      setSectionIndex(prev => prev - 1);
    }
  }

  return (
    <View
      as="section"
      backgroundColor="rgba(243, 243, 243, 1)"
      padding="1.5rem 0 2.5rem"
    >
      <SectionHeader title="Our Story">
        Discover the journey that led to the establishment of Modern Surfaces, LLC,
        <br />
        a testament to our commitment and expertise.
      </SectionHeader>
      <Flex
        padding={{
          base: "0 1.25rem",
          large: "0"
        }}
        gap={{
          base: "1.25rem",
          large: "4rem"
        }}
        maxWidth="988px"
        direction={{
          base: "column",
          medium: "row"
        }}
      >
        <Image
          src={OurStoryImage}
          maxWidth="597px"
          maxHeight="569px"
          width={{
            base: "100%",
            medium: "50%",
            large: "60%"
          }}
          alignSelf="center"
          alt="This photo shows a collage of a man in construction attire hammering something on the ground and a woman in formal attire wearing a headset."
        />
        <Flex
          direction="column"
          gap={{
            base: "1.5rem",
            large: "4rem"
          }}
          justifyContent="space-between"
        >
          <Flex
            direction="column"
            gap={{
              base: "1.5rem",
              large: "2rem"
            }}
          >
            {SECTIONS[sectionIndex - 1]}
          </Flex>
          <Flex
            gap="2rem"
            alignSelf="flex-end"
          >
            <Icon
              width="1.75rem"
              height="2.75rem"
              viewBox={{ width: 26, height: 43 }}
              paths={[
                {
                  d: "M5.01334 0L0 5.0525L16.2844 21.5L0 37.9475L5.01334 43L26.3467 21.5L5.01334 0Z",
                  fill: sectionIndex === 1 ? "rgba(189,189,189,1)" : "rgba(13,155,163,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              transform="rotate(180deg)"
              style={{ cursor: sectionIndex === 1 ? "default" : "pointer" }}
              onClick={prevIndex}
            />
            <Text
              fontSize="1.5rem"
              color="rgba(115,115,115,1)"
              lineHeight="2.5rem"
              textAlign="right"
              display="block"
              direction="column"
              justifyContent="unset"
              whiteSpace="pre-wrap"
            >
              {sectionIndex} / 5
            </Text>
            <Icon
              width="1.75rem"
              height="2.75rem"
              viewBox={{ width: 26, height: 43 }}
              paths={[
                {
                  d: "M5.01334 0L0 5.0525L16.2844 21.5L0 37.9475L5.01334 43L26.3467 21.5L5.01334 0Z",
                  fill: sectionIndex === SECTION_COUNT ? "rgba(189,189,189,1)" : "rgba(13,155,163,1)",
                  fillRule: "nonzero",
                },
              ]}
              display="block"
              style={{ cursor: "pointer" }}
              onClick={nextIndex}
            />
          </Flex>
        </Flex>
      </Flex>
    </View>
  );
}
