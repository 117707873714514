/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Flex, Image, Text, View } from "@aws-amplify/ui-react";
import { NAVIGATION_LABELS, ROUTES, SOCMED_LINKS } from "utils/constants";
import { Link, useNavigate } from "react-router-dom";
import LinkedInIcon from "./LinkedInIcon";
import FacebookIcon from "./FacebookIcon";
import InstagramIcon from "./InstagramIcon";
import { ModernSurfacesLogo, Q360SLogo } from "assets";

const MENU_ITEMS = [
  {
    title: NAVIGATION_LABELS.ABOUT_US,
    route: ROUTES.ABOUT_US
  },
  {
    title: NAVIGATION_LABELS.OUR_SERVICES,
    route: ROUTES.OUR_SERVICES
  },
  {
    title: NAVIGATION_LABELS.GALLERY,
    route: ROUTES.GALLERY
  },
  {
    title: NAVIGATION_LABELS.CONTACT,
    route: ROUTES.CONTACT
  },
];

export default function Footer() {
  const navigate = useNavigate();

  return (
    <View as="footer">
      <View
        padding={{
          base: "0 1.5rem",
          xl: "0"
        }}
      >
        <Flex
          padding={{
            base: "2rem 0",
            large: "5rem 0 2rem"
          }}
          gap="2rem"
          textAlign={{
            base: "center",
            large: "left"
          }}
          maxWidth={{
            base: "100%",
            large: "1050px"
          }}
          direction={{
            base: "column",
            medium: "row"
          }}
          alignItems={{
            base: "center",
            medium: "flex-start"
          }}
          justifyContent={{
            base: "center",
            large: "left"
          }}
          wrap="wrap"
        >
          <Flex
            direction="column"
            flex={{
              large: "1 1 0"
            }}
            width="100%"
            alignItems={{
              base: "center",
              medium: "left"
            }}
          >
            <Image
              src={ModernSurfacesLogo}
              width="275px"
              style={{
                cursor: "pointer"
              }}
              onClick={() => navigate(ROUTES.HOME)}
            />
            <Text
              fontSize="0.875rem"
              lineHeight="1.25rem"
              color="rgba(115, 115, 115, 1)"
            >
              Elevate your space with the unrivaled
              beauty of hardwood flooring. Choose
              Modern Surfaces, LLC - your trusted
              partner in creating stunning surfaces
              that stand the test of time.
            </Text>
          </Flex>
          <View flex="1 1 0" width="100%">
            <Text
              as="h3"
              fontSize="1.5rem"
              fontWeight={700}
              lineHeight="2rem"
              marginBottom={20}
            >
              Our Company
            </Text>
            <Flex
              as="ul"
              direction={{
                base: "row",
                medium: "column"
              }}
              gap="0.75rem"
              justifyContent="space-evenly"
              wrap="wrap"
            >
              {MENU_ITEMS.map((item, index) => (
                <Text
                  key={index}
                  as="li"
                >
                  <Link
                    to={item.route}
                    className="footer-navbar-item"
                  >
                    {item.title}
                  </Link>
                </Text>
              ))}
            </Flex>
          </View>
          <View flex="1 1 0" width="100%">
            <Text
              as="h3"
              fontSize="1.5rem"
              fontWeight={700}
              lineHeight="2rem"
              marginBottom={20}
            >
              Follow Us
            </Text>
            <Flex
              direction={{
                base: "row",
                medium: "column"
              }}
              gap="0.75rem"
              wrap="wrap"
              justifyContent="space-evenly"
            >
              <a
                href={SOCMED_LINKS.FACEBOOK}
                className="socmed-link"
              >
                <Flex
                  gap="0.5rem"
                  justifyContent={{
                    base: "center",
                    large: "left"
                  }}
                >
                  <FacebookIcon isColored isSquare />
                  <Text
                    fontWeight={600}
                    fontSize="0.875rem"
                    lineHeight="1.5rem"
                    color="rgba(115, 115, 115, 1)"
                  >
                    Facebook
                  </Text>
                </Flex>
              </a>
              <span className="socmed-link">
                <Flex
                  gap="0.5rem"
                  justifyContent={{
                    base: "center",
                    large: "left"
                  }}
                >
                  <LinkedInIcon isColored />
                  <Text
                    fontWeight={600}
                    fontSize="0.875rem"
                    lineHeight="1.5rem"
                    color="rgba(115, 115, 115, 1)"
                  >
                    LinkedIn
                  </Text>
                </Flex>
              </span>
              <a
                href={SOCMED_LINKS.INSTAGRAM}
                className="socmed-link"
              >
                <Flex
                  gap="0.5rem"
                  justifyContent={{
                    base: "center",
                    large: "left"
                  }}
                >
                  <InstagramIcon isColored />
                  <Text
                    fontWeight={600}
                    fontSize="0.875rem"
                    lineHeight="1.5rem"
                    color="rgba(115, 115, 115, 1)"
                  >
                    Instagram
                  </Text>
                </Flex>
              </a>
            </Flex>
          </View>
          <View flex="1 1 0" width="100%">
            <Text
              as="h3"
              fontSize="1.5rem"
              fontWeight={700}
              lineHeight="2rem"
              marginBottom={20}
            >
              Contact Us
            </Text>
            <Flex
              as="address"
              direction="column"
              gap="0.75rem"
            >
              <Text
                fontWeight={600}
                fontSize="0.875rem"
                lineHeight="1.5rem"
                color="rgba(115, 115, 115, 1)"
              >
                Seattle, United States
              </Text>
              <a href="tel:(206) 858-0647">
                <Text
                  fontWeight={600}
                  fontSize="0.875rem"
                  lineHeight="1.5rem"
                  color="rgba(115, 115, 115, 1)"
                >
                  (206) 858-0647
                </Text>
              </a>
              <Text
                fontWeight={600}
                fontSize="0.875rem"
                lineHeight="1.5rem"
                color="rgba(115, 115, 115, 1)"
              >
                info@modernsurfaces.pro
              </Text>
            </Flex>
          </View>
        </Flex>
      </View>
      <View
        width="100%"
        backgroundColor="rgba(250, 250, 250, 1)"
        padding="1.5rem 1.25rem"
      >
        <Flex
          gap={{
            base: "1rem",
            small: "2.5rem"
          }}
          wrap="wrap"
          justifyContent={{
            base: "space-evenly",
            medium: "center"
          }}
          marginBottom="1.25rem"
        >
          <Link
            to={ROUTES.PRIVACY_POLICY}
            className="footer-navbar-item"
          >
            {NAVIGATION_LABELS.PRIVACY_POLICY}
          </Link>
          <Link
            to={ROUTES.TERMS_OF_SERVICE}
            className="footer-navbar-item"
          >
            {NAVIGATION_LABELS.TERMS_OF_SERVICE}
          </Link>
          <Link
            to={ROUTES.COOKIE_POLICY}
            className="footer-navbar-item"
          >
            {NAVIGATION_LABELS.COOKIE_POLICY}
          </Link>
        </Flex>
        <Flex
          maxWidth="1050px"
          justifyContent="space-between"
          alignItems="center"
          direction={{
            base: "column",
            medium: "row"
          }}
        >
          <Text
            fontSize="0.875rem"
            lineHeight="1.25rem"
            color="rgba(115, 115, 115, 1)"
          >
            Copyright ©2023 Modern Surfaces, LLC.
            All Right Reserved
          </Text>
          <Flex alignItems="center">
            <Image
              src={Q360SLogo}
              height="2rem"
            />
            <Text
              fontSize="0.875rem"
              lineHeight="1.25rem"
              color="rgba(115, 115, 115, 1)"
            >
              Powered by&nbsp;
              <a href="https://www.q360s.com/" className="q360s-link">
                Quantify 360 Solutions, Inc.
              </a>
            </Text>
          </Flex>
        </Flex>
      </View>
    </View>
  );
}
