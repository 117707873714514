import React from 'react';
import { WhatWeDoExplained } from './sections';
import { CallToAction } from 'ui-components';
import HowWeDoIt from './sections/HowWeDoIt';
import { CALL_TO_ACTION_IMAGES } from 'utils/constants';

const OurServices = () => {
  return (
    <>
      <WhatWeDoExplained />
      <HowWeDoIt />
      <CallToAction image={CALL_TO_ACTION_IMAGES.OUR_SERVICES} />
    </>
  );
}

export default OurServices;
