import * as React from "react";
import { OurStoryItem } from "ui-components";

export default function OurStoryPart4() {
  return (
    <OurStoryItem title="Transforming Spaces">
      Modern Surfaces, LLC is poised to make a profound impact in the flooring industry. With Daniela's expertise in organization and attention to detail and Piotr's vast experience in hardwood flooring, they have all the necessary skills to elevate your space to new heights.

      Through their unmatched craftsmanship and personalized approach, they will bring out the true beauty of wood floors, creating stunning spaces that leave a lasting impression.
    </OurStoryItem>
  );
}
