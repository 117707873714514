import { Flex, Text, View } from '@aws-amplify/ui-react';
import React from 'react';
import GetFreeEstimateButton from './GetFreeEstimateButton';

export default function CallToAction(props) {
  const { image } = props;
  return (
    <View
      width="100%"
      display="block"
      position="relative"
      backgroundImage={`url("${image}")`}
      padding={{
        base: "0 1.25rem",
        large: "0 2.5rem"
      }}
      style={{
        backgroundSize: "cover"
      }}
    >
      <Flex
        maxWidth="1056px"
        padding={{
          base: "3.75rem 0",
          large: "5.25rem 0"
        }}
        textAlign="left"
        alignItems="flex-start"
        direction="column"
        gap={{
          large: "2.5rem"
        }}
      >
        <Text
          fontSize="2.25rem"
          fontWeight="700"
          color="rgba(255,255,255,1)"
          lineHeight="2.75rem"
          textAlign="left"
          display="block"
          maxWidth="817px"
        >
          Elevate your space with the unrivaled beauty of hardwood flooring.
        </Text>
        <GetFreeEstimateButton />
      </Flex>
    </View>
  );
}