import * as React from "react";
import { OurStoryItem } from "ui-components";
export default function OurStoryPart3() {
  return (
    <>
      <OurStoryItem title="Building a Partnership">
        Over the years, Daniela and Piotr nurtured their individual passions and honed their skills. Daniela's exceptional organizational abilities, client relations and marketing expertise, combined with Piotr's mastery in hardwood flooring installation, refinishing, and repair, provided the perfect foundation for a powerful partnership.
      </OurStoryItem>
      <OurStoryItem title="Modern Surfaces, LLC">
        Driven by their shared vision and desire to bring exceptional craftsmanship and personalized service to the flooring industry, They established Modern Surfaces, LLC. Daniela's role as the office manager, overseeing administrative operations, customer service, and marketing, perfectly complements Piotr's leadership in the field, managing a team of skilled craftsmen to ensure excellence.
      </OurStoryItem>
    </>
  );
}
