/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { View } from "@aws-amplify/ui-react";
import { HowWeDoItVideo } from "assets";
import { SectionHeader } from "ui-components";
export default function HowWeDoIt() {
  return (
    <View
      as="section"
      padding={{
        base: "1.25rem",
        large: "1.5rem 6rem"
      }}
      backgroundColor="rgba(243, 243, 243, 1)"
    >
      <SectionHeader title="How We Do It">
        Learn about our approach to delivering exceptional results,
        <br />
        combining skilled craftsmanship and quality materials.
      </SectionHeader>

      <View
        maxWidth={{
          base: "unset",
          xl: "877px"
        }}
      >
        <video width="100%" controls loop>
          <source src={HowWeDoItVideo} type="video/mp4" />
        </video>
      </View>
    </View>
  );
}
