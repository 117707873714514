import { Button, Flex, Grid, Image, Input, Label, Message, PhoneNumberField, Text, TextAreaField, View } from '@aws-amplify/ui-react';
import React, { useState } from 'react';

const FreeQuote = (props) => {
  const { image, imageAlt, freeQuoteRef } = props;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [dialCode, setDialCode] = useState("+1");
  const [location, setLocation] = useState("");
  const [flooring, setFlooring] = useState("");
  const [details, setDetails] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    const fullName = `${firstName} ${lastName}`;
    const formData = {
      name: fullName,
      email: email,
      subject: `Quote Inquiry from ${fullName}`,
      message: `Full Name: ${firstName} ${lastName}\nPhone: ${dialCode} ${phone}\nAddress: ${location}\nEmail: ${email}\nFlooring: ${flooring}\nAdditional details: ${details}`
    };
    // Make an API request to the backend (API Gateway) for form submission
    fetch('https://olxfkem9ja.execute-api.us-east-1.amazonaws.com/production', { // URL that represents the backend API endpoint to which the form data is going to be sent
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then(function (response) {
        if (response.ok) {
          // Redirect to the thank you page
          setShowSuccessMessage(true);
          resetForm();
        } else {
          throw new Error('Form submission failed.');
        }
      })
      .catch(function (error) {
        console.error(error);
        alert('Form submission failed. Please try again later.');
      });

    // window.location.href = `mailto:info@modernsurfaces.pro?subject=Quote Inquiry from ${firstName} ${lastName}&body=Full Name: ${firstName} ${lastName}\nPhone: ${dialCode} ${phone}\nAddress: ${location}\nEmail: ${email}\nFlooring: ${flooring}\nAdditional details: ${details}`
  }

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setDialCode("+1");
    setLocation("");
    setFlooring("");
    setDetails("");
  }

  return (
    <View
      as="section"
      ref={freeQuoteRef}
      width="100%"
      display="block"
      position="relative"
      padding={{
        base: "4.5rem 1.5rem",
        xl: "4.5rem 0"
      }}
    >
      <Image
        src={image}
        position="absolute"
        top="0"
        left="0"
        height="100%"
        width="100%"
        objectFit="cover"
        style={{ zIndex: 0 }}
        alt={imageAlt}
      />
      <Flex
        id="get-quote"
        textAlign="left"
        direction="column"
        gap={{
          base: "1.25rem",
          large: "2rem"
        }}
        backgroundColor="rgba(255,255,255,1)"
        padding={{
          base: "1.25rem 1.5rem",
          medium: "2rem 2.25rem",
          large: "2rem 2.5rem"
        }}
        justifyContent="flex-start"
        alignItems="flex-start"
        maxWidth="645px"
        position="relative"
      >
        <Text
          fontSize={{
            base: "2rem",
            medium: "2.5rem"
          }}
          fontWeight={700}
        >
          Get A Free Quote Here
        </Text>
        {showSuccessMessage && (
          <Message
            variation="filled"
            colorTheme="success"
            heading="Email Inquiry"
            width="100%"
            onDismiss={() => {
              setShowSuccessMessage(false);
            }}
            isDismissible
          >
            Thank you! Your inquiry has been sent.
          </Message>
        )}
        <Grid
          as="form"
          templateColumns={{
            base: "1fr",
            medium: "1fr 1fr"
          }}
          gap={12}
          width="100%"
          onSubmit={sendEmail}
        >
          <FormField
            id="firstName"
            label="First Name"
            placeholder="First name"
            onChange={setFirstName}
            value={firstName}
            isRequired
          />
          <FormField
            id="lastName"
            label="Last Name"
            placeholder="Last name"
            onChange={setLastName}
            value={lastName}
            isRequired
          />
          <FormField
            id="phone"
            label="Phone Number"
            placeholder="234-567-8910"
            onChange={setPhone}
            onDialCodeChange={setDialCode}
            type="tel"
            defaultDialCode={dialCode}
            value={phone}
            isRequired
          />
          <FormField
            id="email"
            label="Email"
            placeholder="example@gmail.com"
            onChange={setEmail}
            type="email"
            value={email}
            isRequired
          />
          <FormField
            id="location"
            label="Job Location Address"
            placeholder="Address"
            onChange={setLocation}
            columnSpan={{
              base: "1",
              medium: "2"
            }}
            value={location}
          />
          <FormField
            id="flooring"
            label="What type of flooring are you looking to install?"
            placeholder="Please tell us more about your needs"
            onChange={setFlooring}
            type="textarea"
            value={flooring}
          />
          <FormField
            id="details"
            label="Additional details about your project"
            placeholder="Please tell us more about your needs"
            onChange={setDetails}
            type="textarea"
            value={details}
          />
          <Button
            variation="primary"
            width="100%"
            size="large"
            disabled={!firstName || !lastName || !phone || !email}
            columnSpan={{
              base: "1",
              medium: "2"
            }}
            type="submit"
          >
            Submit Message
          </Button>
        </Grid>
      </Flex>
    </View>
  );
}

const FormField = (props) => {
  const {
    id,
    label,
    isRequired,
    onChange,
    onDialCodeChange,
    placeholder,
    type = "text",
    ...rest
  } = props;

  const getInput = () => {
    switch (type) {
      case "tel":
        return (
          <PhoneNumberField
            id={id}
            name={id}
            defaultDialCode="+1"
            placeholder={placeholder}
            size="small"
            onChange={(e) => onChange(e.currentTarget.value)}
            onDialCodeChange={(e) => onDialCodeChange(e.currentTarget.value)}
            isRequired={isRequired}
            labelHidden
            {...rest}
          />
        );
      case "textarea":
        return (
          <TextAreaField
            id={id}
            name={id}
            placeholder={placeholder}
            onChange={(e) => onChange(e.currentTarget.value)}
            rows={3}
            isRequired={isRequired}
            labelHidden
            {...rest}
          />
        );
      default:
        return (
          <Input
            id={id}
            name={id}
            placeholder={placeholder}
            onChange={(e) => onChange(e.currentTarget.value)}
            type={type}
            size="small"
            isRequired={isRequired}
            {...rest}
          />
        );
    }
  }

  return (
    <Flex
      direction="column"
      gap="small"
      justifyContent="space-between"
      width="100%"
      {...rest}
    >
      <Label
        htmlFor={id}
        fontWeight="bold"
      >
        {label} {isRequired ? "*" : ""}
      </Label>
      {getInput()}
    </Flex>
  );
}

export default FreeQuote;
