import { Icon, View } from "@aws-amplify/ui-react";
import React from "react";

const StarIcon = ({ isHollow }) => {
  return (
    <View
      width="1.5rem"
      height="1.5rem"
      display="block"
    >
      <Icon
        width="1.25rem"
        height="1.25rem"
        viewBox={{
          width: 20,
          height: 20,
        }}
        paths={[
          {
            d: isHollow
              ? "M11.3452 1.84693C11.2402 1.84693 10.9817 1.87771 10.8444 2.17075L8.73751 6.66617C8.40405 7.37662 7.76137 7.87036 7.0183 7.98364L2.30143 8.70887C1.98989 8.75689 1.88374 9.00314 1.85143 9.10657C1.82259 9.20631 1.77644 9.45995 1.99105 9.67912L5.40178 13.176C5.94523 13.7337 6.19215 14.5378 6.06292 15.3246L5.25986 20.262C5.21024 20.571 5.39139 20.7508 5.47216 20.8124C5.55754 20.8813 5.786 21.018 6.06869 20.8604L10.286 18.5271C10.9506 18.1614 11.7421 18.1614 12.4044 18.5271L16.6205 20.8592C16.9043 21.0155 17.1328 20.8789 17.2193 20.8124C17.3001 20.7508 17.4813 20.571 17.4316 20.262L16.6263 15.3246C16.497 14.5378 16.744 13.7337 17.2874 13.176L20.6981 9.67912C20.9139 9.45995 20.8678 9.20507 20.8377 9.10657C20.8066 9.00314 20.7004 8.75689 20.3889 8.70887L15.672 7.98364C14.9301 7.87036 14.2874 7.37662 13.954 6.66494L11.8448 2.17075C11.7086 1.87771 11.4502 1.84693 11.3452 1.84693ZM5.80331 22.7787C5.32678 22.7787 4.85371 22.6187 4.44871 22.3035C3.74949 21.7568 3.4068 20.8542 3.55565 19.9456L4.35871 15.0081C4.38871 14.8247 4.32987 14.6387 4.20295 14.5082L0.792216 11.0114C0.164531 10.3699 -0.0604663 9.42178 0.204915 8.54141C0.472604 7.6512 1.18106 7.01462 2.05451 6.88164L6.77138 6.15642C6.95368 6.12933 7.1106 6.00989 7.18906 5.83998L9.29712 1.34333C9.68596 0.514677 10.4706 0 11.3452 0C12.2198 0 13.0044 0.514677 13.3932 1.34333L15.5024 5.83875C15.582 6.00989 15.7378 6.12933 15.919 6.15642L20.6358 6.88164C21.5093 7.01462 22.2177 7.6512 22.4854 8.54141C22.7508 9.42178 22.5247 10.3699 21.897 11.0114L18.4862 14.5082C18.3593 14.6387 18.3016 14.8247 18.3316 15.0069L19.1358 19.9456C19.2835 20.8555 18.9408 21.758 18.2405 22.3035C17.5309 22.8588 16.6066 22.9339 15.8232 22.498L11.6082 20.1672C11.4432 20.0761 11.2459 20.0761 11.0809 20.1672L6.86599 22.4992C6.52907 22.6864 6.16562 22.7787 5.80331 22.7787Z"
              : "M18.2961 6.58663C18.2384 6.41682 18.1323 6.26761 17.9908 6.15742C17.8493 6.04723 17.6787 5.98089 17.4999 5.96658L12.2708 5.55107L10.008 0.542056C9.9359 0.380725 9.81869 0.243699 9.67047 0.147514C9.52225 0.0513295 9.34936 9.71158e-05 9.17266 1.37932e-07C8.99597 -9.684e-05 8.82302 0.0509458 8.6747 0.146968C8.52637 0.24299 8.40901 0.379887 8.33677 0.541138L6.07395 5.55107L0.844797 5.96658C0.669105 5.9805 0.501144 6.04474 0.361006 6.15161C0.220868 6.25849 0.114488 6.40348 0.0545932 6.56923C-0.00530196 6.73498 -0.0161763 6.91448 0.0232713 7.08625C0.062719 7.25802 0.150818 7.41479 0.277029 7.5378L4.14134 11.3049L2.77466 17.2229C2.73316 17.402 2.74646 17.5895 2.81283 17.761C2.8792 17.9324 2.99559 18.08 3.14687 18.1845C3.29816 18.2891 3.47737 18.3457 3.66124 18.3471C3.84511 18.3485 4.02516 18.2946 4.17803 18.1924L9.17237 14.8628L14.1667 18.1924C14.3229 18.2961 14.5072 18.3495 14.6947 18.3454C14.8822 18.3413 15.0639 18.2799 15.2155 18.1694C15.367 18.0589 15.4811 17.9047 15.5423 17.7274C15.6036 17.5502 15.6091 17.3584 15.5581 17.1779L13.8805 11.3076L18.0411 7.56348C18.3135 7.31766 18.4135 6.93426 18.2961 6.58663L18.2961 6.58663Z",
            fill: isHollow ? "#0D9BA3" : "rgba(252,166,13,1)",
            fillRule: isHollow ? "evenodd" : "nonzero",
          },
        ]}
        display="block"
      />
    </View>
  );
}

export default StarIcon;
