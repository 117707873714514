import * as React from "react";
import { OurStoryItem } from "ui-components";

export default function OurStoryPart5() {
  return (
    <OurStoryItem title="Conclusion">
      The journey of Daniela and Piotr, from their chance meeting at a fitness class to the establishment of Modern Surfaces, LLC, is a testament to their individual passions and expertise. Daniela's background in fitness and the military, combined with Piotr's mastery in hardwood flooring, have created a partnership driven by a shared commitment to delivering unparalleled craftsmanship, personalized service, and transformative flooring solutions.

      As they embark on this exciting new venture, their focus is on bringing your vision to life and creating beautiful spaces that stand the test of time.
    </OurStoryItem>
  );
}