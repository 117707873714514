/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Flex, Text, View } from "@aws-amplify/ui-react";
import GetFreeEstimateButton from "./GetFreeEstimateButton";
export default function HeroSection() {
  return (
    <Flex
      className="hero-section"
      alignItems="flex-start"
      position="relative"
      padding={{
        base: "3rem 0",
        xl: "6.25rem 0"
      }}
      maxWidth="1223px"
    >
      <Flex
        gap="1.25rem"
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="relative"
        width={{
          base: "100%",
          large: "58.9%"
        }}
      >
        <Text
          as="h1"
          className="h1-text"
          fontSize={{
            base: "2.5rem",
            medium: "3.5rem"
          }}
          lineHeight={{
            base: "3.75rem",
            medium: "5rem"
          }}
          fontWeight={700}
          color="rgba(255,255,255,1)"
          textAlign="left"
          whiteSpace="pre-wrap"
          style={{
            textShadow: "0 0.5rem 0.5rem rgba(0, 0, 0, 0.25)"
          }}
        >
          Elevate Your Space with Unrivaled Beauty
        </Text>
        <View>
          <Text
            fontSize="1.25rem"
            color="rgba(255,255,255,1)"
            lineHeight="2rem"
            textAlign="left"
            display="block"
            direction="column"
            whiteSpace="pre-wrap"
            style={{
              textShadow: "0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)"
            }}
          >
            Transform your home or business with the timeless elegance of hardwood flooring. At Modern Surfaces, LLC, we understand the power of a beautifully crafted floor to elevate your space.
          </Text>
          <br />
          <Text
            fontSize="1.25rem"
            color="rgba(255,255,255,1)"
            lineHeight="2rem"
            textAlign="left"
            display="block"
            direction="column"
            whiteSpace="pre-wrap"
            style={{
              textShadow: "0 0.25rem 0.25rem rgba(0, 0, 0, 0.25)"
            }}
          >
            Our mission is simple:&nbsp;
            <strong>
              to bring timeless elegance and unmatched craftsmanship to every flooring project we undertake.
            </strong>
          </Text>
        </View>
        <GetFreeEstimateButton size="large" />
      </Flex>
    </Flex>
  );
}
