/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Flex, Grid, Image, Text, View } from "@aws-amplify/ui-react";
import { Service } from ".";
import { SectionHeader } from "ui-components";
import { WhatWeDoBanner } from "assets";
export default function WhatWeDoSection() {
  return (
    <View
      as="section"
      display="block"
      position="relative"
      backgroundColor="rgba(255,255,255,1)"
    >
      <View
        padding={{
          base: " 2rem 1.5rem",
          medium: "2.5rem 1.5rem",
          xl: "2.5rem 0"
        }}
      >
        <SectionHeader title="What We Do">
          We provide exceptional hardwood flooring installations
          <br />
          and repairs, transforming spaces with timeless elegance.
        </SectionHeader>
        <Grid
          as="ul"
          maxWidth={{
            base: "unset",
            xl: "1046px"
          }}
          gap={{
            base: "1rem",
            large: "2rem",
          }}
          templateColumns={{
            base: "1fr",
            medium: "1fr 1fr",
            xl: "1fr 1fr 1fr"
          }}
        >
          <Service title="Hardwood Floor & Stairs Installation & Refinishing">
            Expertise in installing and refinishing hardwood floors and stairs, ensuring longevity.
          </Service>
          <Service title="LVP & LVT Style Products Installation">
            We install modern, resilient LVP and LVT flooring products with precision.
          </Service>
          <Service title="Carpet Removal">
            Comprehensive carpet removal services, readying your space for new, elegant flooring.
          </Service>
          <Service title="Hardwood Floor Repair">
            We fix hardwood floor damage including scratches, dents, and water damage.
          </Service>
          <Service title="Swedish and Water-Based Finishes">
            We apply top-quality Swedish and water-based finishes ones for lasting appeal.
          </Service>
          <Service title="Custom Staircase">
            Tailored staircase service, transforming your stairs into a stunning statement piece.
          </Service>
        </Grid>
      </View>
      <Flex
        className="banner"
        width="100%"
        padding="3.25rem 0"
        alignItems="center"
        justifyContent="center"
        position="relative"
      >
        <Text
          as="strong"
          color="#fff"
          fontSize={28}
          fontWeight={700}
          style={{ zIndex: 10 }}
        >
          We Use The Best Brands & Materials
        </Text>
        <Image
          position="absolute"
          objectFit="cover"
          top="0"
          right="0"
          bottom="0"
          left="0"
          width="100%"
          height="100%"
          src={WhatWeDoBanner}
          alt="This photo shows a collage of different types of wood floor paneling."
        />
      </Flex>
    </View>
  );
}
