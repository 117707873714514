import { Flex, Text } from '@aws-amplify/ui-react';
import React from 'react';

const OurStoryItem = (props) => {
  const {
    title, 
    children,
  } = props;
  return (
    <Flex
      as="article"
      gap="0.5rem"
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
      width={327}
    >
      <Text
        as="h3"
        fontSize="1.5rem"
        fontWeight="700"
        color="rgba(37,43,66,1)"
        lineHeight="2rem"
        textAlign="left"
        display="block"
        direction="column"
        position="relative"
        whiteSpace="pre-wrap"
      >
        {title}
      </Text>
      <Text
        fontSize="0.875rem"
        color="rgba(115,115,115,1)"
        lineHeight="1.25rem"
        textAlign="left"
        display="block"
        direction="column"
        whiteSpace="pre-wrap"
      >
        {children}
      </Text>
    </Flex>
  )
}

export default OurStoryItem;
