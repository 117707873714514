import React from 'react';
import { MeetTheOwners, OurProcess, OurStory } from './sections';
import { CallToAction } from 'ui-components';
import { CALL_TO_ACTION_IMAGES } from 'utils/constants';

const AboutUs = () => {
  return (
    <>
      <MeetTheOwners />
      <OurStory />
      <OurProcess />
      <CallToAction image={CALL_TO_ACTION_IMAGES.ABOUT_US} />
    </>
  )
}

export default AboutUs;
