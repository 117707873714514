import React, { useEffect, useRef } from 'react';
import { ReachOut } from './sections';
import { FreeQuote } from 'ui-components';
import { FREE_QUOTE_IMAGES } from 'utils/constants';
import { useLocation } from 'react-router-dom';

const ContactUs = () => {
  const location = useLocation();
  const freeQuoteRef = useRef();

  useEffect(() => {
    if (location && location.hash === "#get-quote") {
      setTimeout(() => {
        freeQuoteRef.current.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  }, [location]);

  return (
    <>
      <ReachOut />
      <FreeQuote
        image={FREE_QUOTE_IMAGES.CONTACT}
        freeQuoteRef={freeQuoteRef}
        imageAlt="This photo shows a fully furnished kitchen."
      />
    </>
  );
}

export default ContactUs;
