import { Button } from "@aws-amplify/ui-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "utils/constants";

const GetFreeEstimateButton = ({ size }) => {
  const navigate = useNavigate();

  return (
    <Button
      variation="primary"
      size={size}
      onClick={() => navigate(ROUTES.CONTACT+"#get-quote")}
    >
      Get a Free Estimate
    </Button>
  );
}

export default GetFreeEstimateButton;
