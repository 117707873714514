/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Grid, View } from "@aws-amplify/ui-react";
import Service from "./Service";
import { SectionHeader } from "ui-components";
import {
  WhatWeDo1,
  WhatWeDo2,
  WhatWeDo3,
  WhatWeDo4,
  WhatWeDo5,
  WhatWeDo6
} from "assets";

const SERVICES = [
  {
    title: "Hardwood Floor & Stairs Installation & Refinishing",
    content: (
      <>
        Our team of hardwood specialists can seamlessly integrate new hardwood floors into your home or business.
        < br /> <br />
        As a part of our services, we also breathe new life into aging hardwood floors and stairs through our  refinishing process, helping them regain their original luster.
      </>
    ),
    image: WhatWeDo1,
    imageAlt: "This photo shows two people holding wooden poles and poking at the floor."
  },
  {
    title: "LVP & LVT Style Products Installation",
    content: (
      <>
        For those seeking the appearance of wood or stone without intensive upkeep, our LVP and LVT flooring installations are an ideal solution.
        <br /><br />
        These  flooring options mimic the look of natural materials, offering a sophisticated touch to any space. Our flooring experts carefully install these vinyl products, ensuring a beautiful and lasting finish.
      </>
    ),
    image: WhatWeDo2,
    imageAlt: "This photo shows someone on the floor placing wooden floor panel while wearing white gloves."
  },
  {
    title: "Carpet Removal",
    content: (
      <>
        If you're planning to transition from carpet to another type of flooring, our team of experts can help.
        <br /><br />
        We perform efficient carpet removal, preparing your space for the next stage of transformation.
        <br /><br />
        We work quickly and thoroughly, minimizing disruption to your routine.
      </>
    ),
    image: WhatWeDo3,
    imageAlt: "This photo shows a pair of hands wearing blue rubber gloves and pulling on a green carpet."
  },
  {
    title: "Hardwood Floor Repair",
    content: (
      <>
        No matter the type of damage - be it scratches, dents, or water damage – our team provides reliable floor fixes to restore the aesthetic and functional integrity of your hardwood.
        <br /><br />
        Our repair services help you maintain the appeal of your floors, enhancing the overall value of your property.
      </>
    ),
    image: WhatWeDo4,
    imageAlt: "This photo shows someone on the floor replacing a wooden floor panel and holding a hammer."
  },
  {
    title: "Swedish and Water-Based Finishes",
    content: (
      <>
        We believe that the right finish can dramatically enhance the look of your floors.
        <br /><br />
        That's why we specialize in applying high-quality Swedish, and water-based finishes. These products offer superior durability and aesthetic appeal, helping your floors withstand daily wear and tear while retaining their elegance.
      </>
    ),
    image: WhatWeDo5,
    imageAlt: "This photo shows an arm holding a roller and applying something on the floor."
  },
  {
    title: "Custom Staircase",
    content: (
      <>
        We believe that the right finish can dramatically enhance the look of your floors.
        <br /><br />
        That's why we specialize in applying high-quality Swedish, and water-based finishes. These products offer superior durability and aesthetic appeal, helping your floors withstand daily wear and tear while retaining their elegance.
      </>
    ),
    image: WhatWeDo6,
    imageAlt: "This photo shows someone polishing a wooden staircase."
  },
];

export default function WhatWeDoExplained() {
  return (
    <View
      as="section"
      padding={{
        base: "1.25rem",
        large: "1.5rem 6rem"
      }}
    >
      <SectionHeader title="What We Do Explained">
        Dive deeper into our comprehensive range of services,
        <br />
        from hardwood flooring installation to custom finishes.
      </SectionHeader>
      <Grid
        as="ul"
        maxWidth={{
          base: "unset",
          xl: "774px"
        }}
        gap={{
          base: "1rem",
          large: "2rem",
        }}
        templateColumns={{
          base: "1fr",
          medium: "1fr 1fr",
          xl: "1fr 1fr 1fr"
        }}
      >
        {SERVICES.map((service, index) => (
          <Service
            key={index}
            title={service.title}
            image={service.image}
            imageAlt={service.imageAlt}
          >
            {service.content}
          </Service>
        ))}
      </Grid>
    </View>
  );
}
