import React, { Component } from 'react';
import { Footer } from 'ui-components'
import {
  AboutUs,
  ContactUs,
  CookiePolicy,
  Gallery,
  HomePage,
  OurServices,
  PrivacyPolicy,
  TermsAndConditions
} from 'pages';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ROUTES } from 'utils/constants';
import UAParser from "ua-parser-js";
import { NavBar } from 'ui-components';
import ScrollToTop from 'ui-components/ScrollToTop';
import { View } from '@aws-amplify/ui-react';

class Home extends Component {
  render() {
    const parser = new UAParser();
    const result = parser.getResult();
    return (
      <div className="pageHome" align="center" style={{ position: "relative" }}>
        <BrowserRouter>
          <NavBar />
          <ScrollToTop />
          <View as="main">
            <Routes>
              <Route path={ROUTES.HOME} element={<HomePage deviceType={result.device.type ?? "desktop"} />} />
              <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
              <Route path={ROUTES.OUR_SERVICES} element={<OurServices />} />
              <Route path={ROUTES.GALLERY} element={<Gallery />} />
              <Route path={ROUTES.CONTACT} element={<ContactUs />} />
              <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
              <Route path={ROUTES.TERMS_OF_SERVICE} element={<TermsAndConditions />} />
              <Route path={ROUTES.COOKIE_POLICY} element={<CookiePolicy />} />
            </Routes>
          </View>
          <Footer />
        </BrowserRouter>
      </div>
    );
  }
}

export default Home;