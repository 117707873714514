import { Flex, Image, Text, View } from '@aws-amplify/ui-react';
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NAVBAR_IMAGES, NAVIGATION_LABELS, PAGE_TITLES, ROUTES, SOCMED_LINKS } from 'utils/constants';
import InstagramIcon from './InstagramIcon';
import HeroSection from './HeroSection';
import HeaderBanner from './HeaderBanner';
import FacebookIcon from './FacebookIcon';
import LinkedInIcon from './LinkedInIcon';
import GetFreeEstimateButton from './GetFreeEstimateButton';
import { ModernSurfacesLogo } from "assets";

const MENU_ITEMS = [
  {
    title: NAVIGATION_LABELS.HOME,
    route: ROUTES.HOME
  },
  {
    title: NAVIGATION_LABELS.ABOUT_US,
    route: ROUTES.ABOUT_US
  },
  {
    title: NAVIGATION_LABELS.OUR_SERVICES,
    route: ROUTES.OUR_SERVICES
  },
  {
    title: NAVIGATION_LABELS.GALLERY,
    route: ROUTES.GALLERY
  },
  {
    title: NAVIGATION_LABELS.CONTACT,
    route: ROUTES.CONTACT
  },
];

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <View position="relative" as="header">
      <Image
        position="absolute"
        top="0"
        left="0"
        height="100%"
        width="100%"
        objectFit="cover"
        style={{ zIndex: 0 }}
        src={location.pathname === ROUTES.HOME ? NAVBAR_IMAGES.HOME : NAVBAR_IMAGES.DEFAULT}
        alt={
          location.pathname === ROUTES.HOME
            ? "This photo shows a living room with wooden floors and white cabinets."
            : "This photo shows a floor with light brown wood panel floorings."
        }
      />
      <View
        padding={{
          base: "1.75rem 2.25rem",
          medium: "1.5rem 2.5rem",
          xl: "1.5rem 2.5rem 0"
        }}
      >
        <Flex
          as="nav"
          maxWidth={1233}
          minHeight={57}
          justifyContent={{
            base: "unset",
            large: "space-between"
          }}
          direction={{
            base: "column",
            large: "row"
          }}
        >
          <Image
            src={ModernSurfacesLogo}
            width={{
              base: "unset",
              large: "22.5%"
            }}
            maxWidth={275}
            alignSelf={{
              base: "center",
              large: "flex-start"
            }}
            style={{
              cursor: "pointer",
              zIndex: 10,
            }}
            onClick={() => navigate(ROUTES.HOME)}
          />
          <Flex
            alignItems="center"
            direction={{
              base: "column",
              large: "row"
            }}
            gap="1.25rem"
            wrap="wrap"
            justifyContent="flex-end"
            style={{
              zIndex: 10,
            }}
          >
            <Flex
              as="ul"
              width={{
                base: "100%",
                medium: "fit-content",
              }}
              gap={{
                base: "1.25rem",
                large: "2.5rem"
              }}
              justifyContent={{
                base: "space-between",
                xl: "unset"
              }}
              wrap="wrap"
            >
              {MENU_ITEMS.map((item, index) => (
                <Text
                  as="li"
                  key={index}
                >
                  <Link
                    to={item.route}
                    className="navbar-item"
                  >
                    {item.title}
                  </Link>
                </Text>
              ))}
            </Flex>
            <GetFreeEstimateButton size="small" />
            <Flex gap={{
              base: "1rem",
              large: "2.25rem"
            }}>
              <a href={SOCMED_LINKS.FACEBOOK} className="navbar-icon">
                <FacebookIcon />
              </a>
              <a href={SOCMED_LINKS.INSTAGRAM} className="navbar-icon">
                <InstagramIcon />
              </a>
              <span className="navbar-icon">
                <LinkedInIcon />
              </span>
            </Flex>
          </Flex>
        </Flex>
        {location.pathname === ROUTES.HOME ? (
          <HeroSection />
        ) : (
          <HeaderBanner title={PAGE_TITLES[location.pathname]} />
        )}
      </View>
    </View>
  );
}

export default Navbar;
