import { Flex, Text } from "@aws-amplify/ui-react";
import React from "react";

const SectionHeader = (props) => {
  const { title, children } = props;
  return (
    <Flex
      gap="0.75rem"
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      marginBottom={45}
    >
      <Text
        as="h2"
        fontSize="2.25rem"
        fontWeight="700"
        color="rgba(37,43,66,1)"
        lineHeight="3.5rem"
        textAlign="left"
        display="block"
        direction="column"
        position="relative"
        whiteSpace="pre-wrap"
      >
        {title}
      </Text>
      <Text
        as="h3"
        fontSize="0.875rem"
        color="rgba(115,115,115,1)"
        lineHeight="1.25rem"
        textAlign="center"
        display="block"
        direction="column"
        whiteSpace="pre-wrap"
      >
        {children}
      </Text>
    </Flex>
  );
}

export default SectionHeader;
