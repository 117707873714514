/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Flex, Icon, Text, View } from "@aws-amplify/ui-react";
import { Link } from "react-router-dom";
import { ROUTES } from "utils/constants";
export default function HeaderBanner(props) {
  const { title } = props;
  return (
    <Flex
      gap="0.25rem"
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      padding={{
        base: "2rem 0 0",
        large: "1rem 0 1.25rem"
      }}
    >
      <Text
        fontSize="2.25rem"
        fontWeight="700"
        color="rgba(37,43,66,1)"
        lineHeight="3.5rem"
        textAlign="center"
        display="block"
        direction="column"
        shrink="0"
        position="relative"
        as="h2"
        whiteSpace="pre-wrap"
      >
        {title}
      </Text>
      <Flex
        gap="1rem"
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        overflow="hidden"
        shrink="0"
        position="relative"
        padding="0.75rem 0"
      >
        <Text
          fontSize="0.875rem"
          fontWeight="600"
          color="rgba(37,43,66,1)"
          lineHeight="1.5rem"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0 0 0 0"
          whiteSpace="pre-wrap"
        >
          <Link
            to={ROUTES.HOME}
            className="breadcrumb-link"
          >
            Home
          </Link>
        </Text>
        <View
          width="0.5rem"
          height="0.75rem"
          display="block"
          gap="unset"
          alignItems="unset"
          justifyContent="unset"
          overflow="hidden"
          shrink="0"
          position="relative"
          padding="0 0 0 0"
        >
          <Icon
            width="0.5rem"
            height="0.75rem"
            viewBox={{ minX: 0, minY: 0, width: 8, height: 12 }}
            paths={[
              {
                d: "M0 11.196L5.35926 6L0 0.804L0.827826 0L7 6.003L0.827826 12L0 11.196Z",
                fill: "rgba(255,255,255,1)",
                fillRule: "evenodd",
              },
            ]}
            display="block"
            gap="unset"
            alignItems="unset"
            justifyContent="unset"
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
          />
        </View>
        <Text
          fontSize="0.875rem"
          fontWeight="600"
          color="rgba(255,255,255,1)"
          lineHeight="1.5rem"
          textAlign="center"
          display="block"
          direction="column"
          justifyContent="unset"
          width="unset"
          height="unset"
          gap="unset"
          alignItems="unset"
          shrink="0"
          position="relative"
          padding="0 0 0 0"
          whiteSpace="pre-wrap"
        >
          {title}
        </Text>
      </Flex>
    </Flex>
  );
}
