import { Flex, Icon, Image, Text, View } from "@aws-amplify/ui-react";
import React from "react";

const Owner = (props) => {
  const { children, image, imageAlt, name, role } = props;
  return (
    <Flex
      as="article"
      gap="2rem"
      direction={{
        base: "column",
        large: "row"
      }}
    >
      <Image
        src={image}
        width="328px"
        maxHeight="347px"
        alignSelf={{
          base: "center",
          large: "left"
        }}
        alt={imageAlt}
      />
      <View textAlign="left">
        <Text
          as="h3"
          fontWeight={700}
          fontSize="2rem"
          lineHeight="2.5rem"
        >
          {name}
        </Text>
        <Text
          as="h4"
          fontSize="1.5rem"
          lineHeight="1.75rem"
        >
          {role}
        </Text>
        <Icon
          width="100%"
          height="1px"
          margin="0.75rem 0"
          viewBox={{ width: 530, height: 1 }}
          paths={[
            {
              d: "M0 0.5L529 0.5L529 -0.5L0 -0.5L0 0.5Z",
              stroke: "rgba(210,212,220,1)",
              fillRule: "nonzero",
              strokeWidth: 1,
            },
          ]}
          display="block"
        />
        <Text
          fontSize="0.88rem"
          lineHeight="1.25rem"
        >
          {children}
        </Text>
      </View>
    </Flex>
  );
}

export default Owner;