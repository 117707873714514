/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Grid, Image, View } from "@aws-amplify/ui-react";
import { SectionHeader } from "ui-components";
import { WORK_IMAGES } from "utils/constants";
import Lightbox from "yet-another-react-lightbox";

export default function SeeMoreOfOurWork() {
  const [showImage, setShowImage] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(0);

  return (
    <View
      as="section"
      padding="1.5rem 0 2.25rem"
    >
      <SectionHeader title="See More Of Our Work">
        Inspiration at Every Turn: Explore Our Gallery of
        <br />
        Exquisite Flooring & Stair Transformations.
      </SectionHeader>
      <Grid
        maxWidth="1120px"
        templateColumns={{
          base: "1fr",
          medium: "1fr 1fr",
          xl: "repeat(3, 1fr)"
        }}
        gap="1.25rem"
        className="work-images"
        autoFlow="dense"
        padding={{
          base: "0 1.25rem",
          xl: "0"
        }}
      >
        {WORK_IMAGES.map((image, index) => (
          <View
            key={index}
            style={{ cursor: "pointer" }}
            className="work-image"
            minHeight="230px"
            position="relative"
            onClick={() => {
              setImageIndex(index)
              setShowImage(true);
            }}
          >
            <Image
              position="absolute"
              top="0"
              left="0"
              height="100%"
              width="100%"
              objectFit="cover"
              src={image.src}
              alt={image.alt}
            />
          </View>
        ))}
      </Grid>
      <Lightbox
        open={showImage}
        close={() => setShowImage(false)}
        index={imageIndex}
        slides={WORK_IMAGES}
      />
    </View>
  );
}
