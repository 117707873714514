/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { Image, View } from "@aws-amplify/ui-react";
import Carousel from "react-multi-carousel";
import { SectionHeader } from "ui-components";
import { WORK_IMAGES } from "utils/constants";
import Lightbox from "yet-another-react-lightbox";

export default function OurWork(props) {
  const [showImage, setShowImage] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(0);

  const { deviceType } = props;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { min: 0, max: 1024},
      items: 1,
    },
  };

  return (
    <View
      as="section"
      width="100%"
      display="block"
      overflow="hidden"
      position="relative"
      padding="1.25rem 0 2.25rem"
      backgroundColor="rgba(243,243,243,1)"
    >
      <SectionHeader title="Our Work">
        Witness the Beauty:
        <br />
        Our Impeccable Craftsmanship in Action
      </SectionHeader>
      <View position="relative" height={574}>
        <Carousel
          itemClass="image-item"
          responsive={responsive}
          ssr
          swipeable
          centerMode={deviceType==="desktop"}
          infinite
        >
          {WORK_IMAGES.map((image, index) => {
            return (
              <View
                key={index}
                padding="0 0.75rem"
                position="relative"
                height={574}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setImageIndex(index)
                  setShowImage(true);
                }}
                margin="0 0.75rem"
              >
                <Image
                  src={image.src}
                  alt={image.alt}
                  position="absolute"
                  top="0"
                  left="0"
                  bottom="0"
                  right="0"
                  objectFit="cover"
                  width="100%"
                  height="100%"
                />
              </View>
            );
          })}
        </Carousel>
        <Lightbox
          open={showImage}
          close={() => setShowImage(false)}
          index={imageIndex}
          slides={WORK_IMAGES}
        />
      </View>
    </View>
  );
}
