import { Flex, Image, Text } from '@aws-amplify/ui-react';
import React from 'react';

const Service = (props) => {
  const {
    image,
    imageAlt,
    title,
    children,
  } = props;
  return (
    <Flex
      as="li"
      gap="0"
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
      position="relative"
      backgroundColor="rgba(243,243,243,1)"
    >
      <Image
        maxHeight="138px"
        width="100%"
        display="block"
        objectFit="cover"
        src={image}
        alt={imageAlt}
      />
      <Flex
        gap="0.5rem"
        direction="column"
        height="unset"
        justifyContent="flex-start"
        alignItems="flex-start"
        position="relative"
        padding="0.75rem 2rem 2rem 1.25rem"
      >
        <Text
          as="h3"
          fontWeight="700"
          color="rgba(37,43,66,1)"
          lineHeight="1.5rem"
          textAlign="left"
          display="block"
          whiteSpace="pre-wrap"
        >
          {title}
        </Text>
        <Text
          fontSize="0.875rem"
          color="rgba(115,115,115,1)"
          lineHeight="1.25rem"
          textAlign="left"
          display="block"
        >
          {children}
        </Text>
      </Flex>
    </Flex>
  );
}

export default Service;
