import * as React from "react";
import { OurStoryItem } from "ui-components";
export default function OurStoryPart2() {
  return (
    <>
      <OurStoryItem title="Piotr's Expertise">
        Piotr's love for hardwood flooring and his extensive experience as a master craftsman have made him a renowned figure in the industry. With almost 20 years of expertise in hardwood flooring, Piotr brings unparalleled craftsmanship, meticulous attention to detail, and a deep understanding of the artistry behind transforming spaces with the timeless beauty of wood floors..
      </OurStoryItem>
      <OurStoryItem title="The Meeting">
        It was at a fitness class where Daniela and Piotr's paths first crossed. Little did they know that this chance encounter would be the start of a remarkable journey. Bonded by their shared passion for health, fitness, and a love for hardwood flooring, they began to envision a partnership that combined their unique backgrounds and expertise.
      </OurStoryItem>
    </>
  );
}
